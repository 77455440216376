import React from 'react'

import { CompanyType, PersonType } from 'types'

import { Helmet } from 'components/Helmet'
import { routeList } from 'routes'
import { LIMIT } from 'config/pagination'
import { useAccount } from 'hooks/useAccount'
import { Skeleton } from 'components/Skeleton'
import { getPageCount } from 'utils/pagination'
import { PageWrapper } from 'components/PageWrapper'
import { useGetPersonalAccountsListQuery } from 'store/personal-accounts'
import { PersonalAccountList } from 'components/PersonalAccountList'
import {
  PersonalAccountFilters,
  PersonalAccountFiltersFormState,
  ALL_COMPANY_KEY,
} from 'components/PersonalAccountFilters'
import houseIcon from 'assets/icons/house-icon.svg'
import { filterQueryParams } from 'utils/filterQueryParams'
import { Error } from 'components/Error'

import { useMainPageStyles } from './style'

export const MainPage = () => {
  const { defaultOperatorCompanyType, isSupervisor } = useAccount()
  const classes = useMainPageStyles()

  // --- state ---
  const [page, setPage] = React.useState(0)
  const [formState, setFormState] = React.useState<PersonalAccountFiltersFormState>({
    personType: PersonType.NATURAL_PERSON,
    company: defaultOperatorCompanyType ?? CompanyType.KYIV_ENERGY_SERVICES,
  })

  // --- queries ---
  const { data, isFetching, error, isSuccess } = useGetPersonalAccountsListQuery(
    filterQueryParams({
      offset: page * LIMIT,
      limit: LIMIT,
      ...formState,
      company: formState?.company === ALL_COMPANY_KEY ? undefined : formState?.company,
    })
  )

  // --- handlers ---
  const handleSubmitPersonalAccountFilters = (formState: PersonalAccountFiltersFormState) => {
    setFormState({
      ...formState,
      ...(formState.phoneNumber && { phoneNumber: formState.phoneNumber.replace(/\D/g, '') }),
    })
    setPage(0)
  }

  // --- effects ---
  React.useEffect(() => {
    if (isSupervisor) {
      // Check if the tabs have already been opened during this session
      const escalationTabOpened = sessionStorage.getItem('escalationTabOpened')
      const reportTabOpened = sessionStorage.getItem('reportTabOpened')

      if (!escalationTabOpened) {
        window.open(routeList.escalation, '_blank')
        sessionStorage.setItem('escalationTabOpened', 'YES')
      }

      if (!reportTabOpened) {
        window.open(routeList.reportByTheme, '_blank')
        sessionStorage.setItem('reportTabOpened', 'YES')
      }
    }
  }, [isSupervisor])

  return (
    <PageWrapper>
      <Helmet title="Головна" link={[{ rel: 'icon', type: 'image/png', href: houseIcon }]} />

      <PersonalAccountFilters
        showRequestNumber
        onSubmit={handleSubmitPersonalAccountFilters}
        defaultValues={formState}
      />
      {error && <Error error={error} />}
      {isFetching && <Skeleton rows={6} />}
      {!isFetching && isSuccess && (
        <>
          <h2 className={classes.title}>Знайдено особових рахунків: {data.totalCount}</h2>
          <PersonalAccountList
            page={page}
            onPageChange={setPage}
            data={data.items}
            showCreateRequestButton={false}
            pageCount={getPageCount(data.totalCount, LIMIT)}
          />
        </>
      )}
    </PageWrapper>
  )
}
