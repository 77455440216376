import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { registerIcons } from '@fluentui/react'

import './index.css'
import { App } from './App'
import { store } from 'store/store'
import MSAuth from 'containers/MSAuth'
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check-icon.svg'
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-icon.svg'
import reportWebVitals from './reportWebVitals'

async function enableMocking() {
  if (process.env.REACT_APP_MODE === 'dev') {
    const { worker } = await import('./mocks/browser')

    return worker.start()
  }
}

registerIcons({
  icons: {
    Cancel: <CloseIcon />,
    CheckMark: <CheckIcon height={10} fill="#fff" />,
    ChevronDown: <ChevronIcon style={{ marginTop: '4px' }} />,
  },
})

const AuthProvider = process.env.REACT_APP_MODE === 'dev' ? React.Fragment : MSAuth

enableMocking().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </Provider>
    </BrowserRouter>
  )
})

reportWebVitals()
