import { Option, PersonType } from 'types'

const personTypeLabel: Record<PersonType, string> = {
  [PersonType.LEGAL_ENTITY]: 'Юридична особа',
  [PersonType.NATURAL_PERSON]: 'Фізична особа',
}

export const getPersonTypeLabel = (personType?: PersonType): string => {
  if (!personType) return ''
  return personTypeLabel[personType]
}

export const personTypeList = [PersonType.LEGAL_ENTITY, PersonType.NATURAL_PERSON] as const

export const personTypeOptions: Option<PersonType>[] = personTypeList.map((key) => ({
  key,
  text: getPersonTypeLabel(key),
}))
