import React from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { PrimaryButton, TextField, MaskedTextField, Dropdown } from '@fluentui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { ReactComponent as SearchIcon } from 'assets/icons/search-icon.svg'
import { personTypeList, personTypeOptions } from 'config/personType'
import { companyTypeList, companyTypeOptions } from 'config/companyType'
import { useGetCustomerServicesDictionaryQuery } from 'store/dictionaries'
import { phoneMask } from 'config/phone'

import { useMainPageFiltersStyles } from './styles'
import { CompanyType } from 'types'
import { phoneSchemaOptional } from 'utils/validation'

export const ALL_COMPANY_KEY = 'ALL'
const companyTypeListWithAll = [ALL_COMPANY_KEY, ...companyTypeList] as const
const companyTypeOptionsWithAll = [{ key: ALL_COMPANY_KEY, text: 'Скрізь' }, ...companyTypeOptions]

const formSchema = z.object({
  number: z.string().optional(),
  fullName: z.string().optional(),
  office: z.string().optional(),
  address: z.string().optional(),
  phoneNumber: phoneSchemaOptional,
  personType: z.enum(personTypeList).optional(),
  requestNumber: z.string().optional(),
  company: z.enum(companyTypeListWithAll).optional(),
  customerServiceCentreId: z.number().optional(),
})

export type PersonalAccountFiltersFormState = z.infer<typeof formSchema>

interface PersonalAccountFiltersProps {
  showRequestNumber?: boolean
  showResetFiltersButton?: boolean
  disableCompanySelect?: boolean
  defaultValues?: Partial<PersonalAccountFiltersFormState>
  onSubmit: (formValues: PersonalAccountFiltersFormState) => void
  onReset?: (formValues: PersonalAccountFiltersFormState) => void
}

export const PersonalAccountFilters: React.FC<PersonalAccountFiltersProps> = ({
  onSubmit,
  onReset,
  defaultValues,
  showRequestNumber = false,
  showResetFiltersButton = false,
  disableCompanySelect = false,
}) => {
  const classes = useMainPageFiltersStyles()
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<PersonalAccountFiltersFormState>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      number: '',
      fullName: '',
      customerServiceCentreId: undefined,
      address: '',
      phoneNumber: '',
      personType: undefined,
      company: undefined,
      requestNumber: undefined,
      ...defaultValues,
    },
  })

  const company = useWatch({ control, name: 'company' })
  const companySelected = company !== undefined && company !== ALL_COMPANY_KEY

  const { data: customerServiceDictionary = [] } = useGetCustomerServicesDictionaryQuery(
    { company: company === ALL_COMPANY_KEY ? undefined : company },
    { skip: !companySelected }
  )

  const customerServiceOptions = React.useMemo(() => {
    return customerServiceDictionary?.map((item) => ({
      key: item.id,
      text: item.value,
    }))
  }, [customerServiceDictionary])

  const handleResetFilters = () => {
    const initialFormValues = {
      number: '',
      fullName: '',
      customerService: undefined,
      address: '',
      phoneNumber: '',
      personType: undefined,
      company: undefined,
      requestNumber: undefined,
      ...defaultValues,
    }
    reset(initialFormValues)
    onReset?.(initialFormValues)
  }

  const handleCompanyChange = (value?: CompanyType) => {
    setValue('company', value)
    setValue('customerServiceCentreId', undefined)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Controller
        name="number"
        control={control}
        render={(props) => (
          <TextField
            {...props.field}
            errorMessage={errors[props.field?.name]?.message as string}
            label="Номер О/Р"
            placeholder="Введіть О/Р"
            className={classes.number}
          />
        )}
      />
      <Controller
        name="fullName"
        control={control}
        render={(props) => (
          <TextField
            {...props.field}
            errorMessage={errors[props.field?.name]?.message as string}
            label="ПІБ власника/назва юр. особи"
            placeholder="Введіть ПІБ власника/назва юр. особи"
            className={classes.fullName}
          />
        )}
      />
      <Controller
        name="customerServiceCentreId"
        control={control}
        render={(props) => (
          <Dropdown
            {...props.field}
            errorMessage={errors[props.field?.name]?.message as string}
            disabled={!companySelected}
            label="ЦОК"
            placeholder="Введіть ЦОК"
            options={customerServiceOptions}
            onChange={(e, option) => props.field.onChange(option?.key)}
            selectedKey={props.field.value ?? null}
            className={classes.customerService}
          />
        )}
      />
      <Controller
        name="address"
        control={control}
        render={(props) => (
          <TextField
            {...props.field}
            errorMessage={errors[props.field?.name]?.message as string}
            label="Адреса постачання"
            placeholder="Введіть адресу"
            className={classes.address}
          />
        )}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={(props) => (
          <MaskedTextField
            {...props.field}
            errorMessage={errors[props.field?.name]?.message as string}
            label="Номер телефону"
            placeholder="Введіть номер"
            mask={phoneMask}
            className={classes.phoneNumber}
          />
        )}
      />
      <Controller
        name="personType"
        control={control}
        render={(props) => (
          <Dropdown
            {...props.field}
            onChange={(e, option) => props.field.onChange(option?.key)}
            selectedKey={props.field.value ?? null}
            errorMessage={errors[props.field?.name]?.message as string}
            label="Ознака"
            placeholder="Введіть ознаку"
            options={personTypeOptions}
            className={classes.personType}
          />
        )}
      />
      <Controller
        name="company"
        control={control}
        render={(props) => (
          <Dropdown
            onChange={(e, option) => handleCompanyChange(option?.key as CompanyType)}
            selectedKey={props.field.value ?? null}
            errorMessage={errors[props.field?.name]?.message as string}
            label="Де шукати?"
            placeholder="Де шукати?"
            options={companyTypeOptionsWithAll}
            disabled={disableCompanySelect}
            className={classes.company}
          />
        )}
      />
      {showRequestNumber && (
        <Controller
          name="requestNumber"
          control={control}
          render={(props) => (
            <TextField
              {...props.field}
              errorMessage={errors[props.field?.name]?.message as string}
              label="Номер звернення"
              placeholder="Введіть номер звернення"
              className={classes.requestNumber}
            />
          )}
        />
      )}

      <PrimaryButton type="submit" className={classes.submitButton}>
        <SearchIcon />
        &nbsp;Знайти
      </PrimaryButton>
      {showResetFiltersButton && (
        <PrimaryButton type="reset" onClick={handleResetFilters} className={classes.resetButton}>
          Скинути фільтри
        </PrimaryButton>
      )}
    </form>
  )
}
