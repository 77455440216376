import React, { Suspense } from 'react'

import MsalAuth, { ACCESS_TOKEN_NAME } from './MsalAuth'
import { useAppDispatch } from 'store/store'
import { getMe } from 'store/account'

const msal = new MsalAuth()

const AuthContainer = () => {
  const dispatch = useAppDispatch()

  return React.lazy(
    () =>
      new Promise((resolve) => {
        const handleLoginErrors = (errObj: any) => {
          if (errObj.status === 403) {
            return resolve(import('./NotAuthenticatedApp') as any)
          }

          return resolve(import('./AuthenticatedApp') as any)
        }

        const setUserTokenAndRenderApp = ({ accessToken }: { accessToken: string }) => {
          localStorage.setItem(ACCESS_TOKEN_NAME, accessToken)

          dispatch(getMe())
            .unwrap()
            .then((data: any) => {
              if (data.error) return handleLoginErrors(data.payload)

              return resolve(import('./AuthenticatedApp') as any)
            })
            .catch((err) => {
              console.error('load user data Error', err)
              msal.logout()
            })
        }

        msal
          .getAccessToken()
          .then(setUserTokenAndRenderApp)
          .catch(({ errorCode = null }) => {
            if (msal.requiresInteraction(errorCode)) {
              msal.acquireTokenRedirect()
              return
            }
            if (msal.isUserLoginError(errorCode)) {
              msal.redirectToLogin()
            }
          })
      })
  )
}

interface MSAuthProps {
  children: React.ReactNode
}

const MSAuth: React.FC<MSAuthProps> = (props) => {
  const AuthAction = AuthContainer()

  return (
    <Suspense fallback={<div>loading...</div>}>
      <AuthAction>{props.children}</AuthAction>
    </Suspense>
  )
}

export { msal }
export default MSAuth
