import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { RequestType } from 'types'
import { useAccount } from 'hooks/useAccount'
import { GetRequestResponseDto, useGetNextNumberQuery, useGetRequestQuery } from 'store/requests'
import { useInternalCompany } from './useInternalCompany'

/**
 * Hook for getting request data
 * works in 2 scenarios:
 * 1. If request id is passed in url params, then it will get request data by id (editing)
 * 2. If call id is passed in url params, then it will get request data by call id (creation)
 */
export const useRequest = (): {
  data?: GetRequestResponseDto
  isLoading: boolean
} => {
  const { requestId } = useParams<{ id: string; requestId?: string }>()
  const [searchParams] = useSearchParams()
  const call = searchParams.get('callId')
  const phone = searchParams.get('ani')!
  const company = searchParams.get('company')
  const contactCenterPhone = searchParams.get('dn')!
  const internalCompany = useInternalCompany(company)

  const { user } = useAccount()

  // queries
  const { data: requestData, isLoading: isRequestLoading } = useGetRequestQuery(requestId!, { skip: !requestId })
  const { data: nextNumberData } = useGetNextNumberQuery(undefined, { skip: !call,  })

  const data: GetRequestResponseDto | undefined = React.useMemo(() => {
    if (requestId) {
      return requestData
    }

    if (nextNumberData) {
      return {
        id: '',
        type: RequestType.INCOMING_CALL,
        number: nextNumberData,
        createdAt: new Date().toISOString(),
        incomePhoneNumber: phone,
        contactCenterPhoneNumber: contactCenterPhone,
        company: internalCompany,
        authorFullName: user?.fullName ?? '',
        keywords: [],
        editorFullName: '',
        escalation: undefined,
        reasonType: undefined,
        supplyType: undefined,
        category: '',
        comment: '',
        theme: '',
        subTheme: '',
        personalNumber: '',
      } as unknown as GetRequestResponseDto
    }
  }, [requestId, nextNumberData, requestData, phone, contactCenterPhone, internalCompany, user?.fullName])

  const isLoading = React.useMemo(() => {
    return isRequestLoading || (!requestId && !nextNumberData)
  }, [isRequestLoading, requestId, nextNumberData])

  return {
    data,
    isLoading,
  }
}
