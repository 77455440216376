import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { Helmet } from 'components/Helmet'
import { PersonType } from 'types'
import { Box } from 'components/Box'
import { Skeleton } from 'components/Skeleton'
import { PageWrapper } from 'components/PageWrapper'
import { RequestDetails } from 'components/RequestDetails'
import { PersonalAccountHistory } from 'components/PersonalAccountListItem/PersonalAccountHistory'
import { RequestForm, RequestFormState } from 'components/RequestForm'
import { ContactForm, ContactFormState } from 'components/ContactForm'
import {
  useGetContactQuery,
  useUpdateLegalEntityContactMutation,
  useUpdateNaturalPersonContactMutation,
} from 'store/contacts'
import { useCreateRequestMutation, useUpdateRequestMutation } from 'store/requests'
import { useAccount } from 'hooks/useAccount'
import { mapContactToEdit } from 'utils/mappers/mapContactToEdit'
import { mapRequestToEdit } from 'utils/mappers/mapRequestToEdit'
import addRequestIcon from 'assets/icons/add-request-icon.svg'
import editRequestIcon from 'assets/icons/edit-request-icon.svg'
import { mapLegalEntityContactToSubmit, mapNaturalPersonContactToSubmit } from 'utils/mappers/mapContactToSubmit'
import { mapAccountToSubmit } from 'utils/mappers/mapRequestToSubmit'
import { buildContactFullName } from 'utils/mappers/contactFullName'
import { mapPhoneToBackend } from 'utils/phone'
import { useRequest } from 'hooks/useRequest'
import { useInternalCompany } from 'hooks/useInternalCompany'
import { isEntryDuplicateException } from 'utils/errors'
import { useGoBackOrClose } from 'hooks/useGoBackOrClose'
import { useBeforeUnload } from 'hooks/useBeforeUnload'

import { useContactPageStyles } from './styles'

/**
 * /?ani=0645416787&dn=0645416787&callId=12345A&company=DNEP&agentLogin=kulebamb&team=LAB.TEAM&ext=380975712345
 * ext - номер телефону агента, який прйняв дзвінок
 * team - команда, в якій знаходиться агент
 */
export const ContactRequestPage: React.FC = () => {
  const classes = useContactPageStyles()
  const { id, requestId } = useParams<{ id: string; requestId: string }>()
  const { isSupervisorInCompany, isInCompany } = useAccount()
  const goBackOrClose = useGoBackOrClose()
  const [searchParams] = useSearchParams()
  const phone = searchParams.get('ani')!
  const company = searchParams.get('company')
  const contactCenterPhone = searchParams.get('dn')!
  const agentPhoneNumber = searchParams.get('ext')!
  const team = searchParams.get('team')!
  const callId = searchParams.get('callId')!

  // --- queries ---
  const { data: request } = useRequest()
  const { data: contact } = useGetContactQuery(id!)
  const internalCompany = useInternalCompany(company)

  // --- mutations ---
  const [createRequest, createRequestParams] = useCreateRequestMutation()
  const [updateRequest, updateRequestParams] = useUpdateRequestMutation()
  const [updateLegalEntityContact, updateLegalEntityParams] = useUpdateLegalEntityContactMutation()
  const [updateNaturalPersonContact, updateNaturlaPersonParams] = useUpdateNaturalPersonContactMutation()

  // --- side effects ---
  const submitted = [
    createRequestParams.isSuccess,
    updateRequestParams.isSuccess,
    updateLegalEntityParams.isSuccess,
    updateNaturlaPersonParams.isSuccess,
  ].some(Boolean)
  useBeforeUnload('Ви впевнені, що хочете залишити цю сторінку? Всі незбережені дані будуть втрачені.', !submitted)

  // --- handlers ---
  const handleUpdateContact = async (formState: ContactFormState) => {
    try {
      if (formState.personType === PersonType.LEGAL_ENTITY) {
        await updateLegalEntityContact({ id: +id!, ...mapLegalEntityContactToSubmit(formState) }).unwrap()
      }

      if (formState.personType === PersonType.NATURAL_PERSON) {
        await updateNaturalPersonContact({ id: +id!, ...mapNaturalPersonContactToSubmit(formState) }).unwrap()
      }

      goBackOrClose()
    } catch {
      alert('Помилка при збереженні контакту')
    }
  }

  const handleSubmitRequestForm = async (formState: RequestFormState) => {
    try {
      const dto = {
        ...mapAccountToSubmit(formState),
        contactId: +id!,
      }

      if (requestId) {
        await updateRequest({
          ...dto,
          id: +requestId,
        }).unwrap()
      } else {
        await createRequest({
          ...dto,
          number: request!.number,
          company: internalCompany!,
          incomePhoneNumber: mapPhoneToBackend(phone),
          contactCenterPhoneNumber: mapPhoneToBackend(contactCenterPhone),
          ...(callId && { callId }),
          ...(team && { team }),
          ...(agentPhoneNumber && { agentPhoneNumber: parseInt(agentPhoneNumber) }),
        }).unwrap()
      }

      goBackOrClose()
    } catch (e) {
      if (isEntryDuplicateException(e)) {
        throw {
          phone: 'Номер вже додано',
        }
      }
      alert('Помилка при збереженні контакту')
    }
  }

  const handleCancel = () => {
    goBackOrClose()
  }

  const disableForm = React.useMemo(() => {
    if (!request) return true

    if (requestId) {
      return !isSupervisorInCompany(request?.company)
    }

    return !isInCompany(internalCompany!)
  }, [internalCompany, isInCompany, isSupervisorInCompany, request, requestId])

  if (!contact) {
    return <Skeleton rows={6} />
  }

  return (
    <PageWrapper className={classes.container}>
      <Helmet
        title={
          requestId
            ? `Редагування звернення: ${buildContactFullName(contact)}`
            : `Нове звернення: ${buildContactFullName(contact)}`
        }
        link={[{ rel: 'icon', type: 'image/png', href: requestId ? editRequestIcon : addRequestIcon }]}
      />
      <RequestForm
        className={classes.form}
        onSubmit={handleSubmitRequestForm}
        defaultValues={request && mapRequestToEdit(request)}
        disabled={disableForm}
        controlProps={{
          // if edit request - disable next fields
          keywords: { disabled: !!requestId },
          escalation: { disabled: !!requestId },
        }}
      />
      <RequestDetails
        className={classes.details}
        id={request?.callId ?? callId}
        number={request?.number}
        createdAt={request?.createdAt}
        company={request?.company}
        incomePhoneNumber={request?.incomePhoneNumber}
        contactCenterPhoneNumber={request?.contactCenterPhoneNumber}
        authorFullName={request?.createdBy}
        editorFullName={request?.editedBy}
        agentPhone={agentPhoneNumber}
        team={team}
      />
      <Box className={classes.contact}>
        <ContactForm onCancel={handleCancel} onSubmit={handleUpdateContact} defaultValues={mapContactToEdit(contact)} />
      </Box>
      <PersonalAccountHistory contactId={+id!} className={classes.history} scrollableHistory rounded limit={10} />
    </PageWrapper>
  )
}
