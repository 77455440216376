import React from 'react'
import { useAppSelector } from 'store/store'
import { UserCompanyRole, CompanyType } from 'types'

export const useAccount = () => {
  const user = useAppSelector((state) => state.account.user)
  const isLoggedIn = Boolean(user)

  const defaultOperatorCompanyType = React.useMemo(() => user?.type[0].type, [user])

  const isInCompany = React.useCallback(
    (companyType: CompanyType) => {
      return user?.type?.some((company) => company.type === companyType)
    },
    [user]
  )

  const isSupervisor = React.useMemo(
    () => user?.type?.some((company) => company.role === UserCompanyRole.SUPERVISOR),
    [user]
  )

  /**
   * actually check if user is supervisor or admin in company
   */
  const isSupervisorInCompany = React.useCallback(
    (companyType: CompanyType) => {
      return user?.type?.some(
        (company) =>
          [UserCompanyRole.SUPERVISOR, UserCompanyRole.ADMIN].includes(company.role) && company.type === companyType
      )
    },
    [user]
  )

  const isAdmin = React.useMemo(() => {
    const companiesCount = Object.values(CompanyType).length

    return (
      user?.type?.length === companiesCount &&
      user?.type?.every((company) => company.role === UserCompanyRole.SUPERVISOR)
    )
  }, [user])

  const supervisorCompanyTypes = React.useMemo(() => {
    return user?.type?.filter((company) => company.role === UserCompanyRole.SUPERVISOR).map((company) => company.type)
  }, [user])

  return {
    user,
    isLoggedIn,
    isSupervisor,
    isAdmin,
    defaultOperatorCompanyType,
    supervisorCompanyTypes,
    isSupervisorInCompany,
    isInCompany,
  }
}
